import {
  FETCH_GROUPS,
  GET_GROUPS,
  GET_PATIENTS,
  FILTER_GROUPS,
  FILTER_PATIENTS,
  REMOVE_GROUP,
  GROUP_NAME_UPDATED,
  LOAD_MORE_GROUPS,
  SEARCHING_STATUS,
  NEW_GROUP,
  BEEN_FORWARDED_TO_CCM,
  LABEL_RESULT,
  LABEL_STATUS,
  LABEL_ITEM_STATUS,
  SET_SIDE_NAV_TAB,
} from "./types";
import Parse from "parse";
import sort from "fast-sort";
import { parseAction } from "./REST";
import moment from "moment";
import config from "../config";
import InsuranceApi from "../api/Insurance";

const $ = window.$;

export const fetchGroups = (groupType = "regular", willLoadMore = true, page = 0) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("post", config.BASE_URL + "/parse/functions/downloadGroupsV2", {
      page: page,
      pageSize: 15,
      groupType: groupType,
    })
      .then((results) => {
        let tempGroup = [...results.result.circles];

        if (willLoadMore) {
          let sortedGroup = sort(tempGroup).asc((p) => p.name.toLowerCase());
          dispatch({
            type: LOAD_MORE_GROUPS,
            payload: sortedGroup,
            groupType: groupType,
          });
        } else {
          let sortedGroup = sort(tempGroup).asc((p) => p.name.toLowerCase());
          dispatch({
            type: FETCH_GROUPS,
            payload: sortedGroup,
            status: "SUCCESS",
            groupType: groupType,
          });
        }
        resolve(tempGroup.length);
      })
      .catch((error) => {
        if (!willLoadMore) {
          dispatch({
            type: FETCH_GROUPS,
            status: "FAILED",
            groupType: groupType,
          });
        }
        reject(error);
      });
  });
};

export const fetchLabel = (className, columnName) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: LABEL_STATUS,
      label: className,
      status: "FETCHING",
    });

    parseAction(
      "post",
      config.BASE_URL + "/parse/functions/fetchPatientLabels",
      {
        className: className,
        columnName: columnName,
      },
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        dispatch({
          type: LABEL_RESULT,
          label: className,
          items: res.result.labels,
        });
        resolve(res.result.labels);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchPatients = (
  groupType = "patient",
  willLoadMore = true,
  page = 0,
  label = undefined,
  labelObjectId,
  columnName,
  pageSize
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (pageSize != "") pageSize = 10;
    let query = {
      page: page,
      pageSize: pageSize,
    };

    if (label) {
      dispatch({
        type: LABEL_ITEM_STATUS,
        label: label,
        labelObjectId: labelObjectId,
        isFetching: true,
        groups: [],
      });
      query.className = label;
      query.classNameObjectId = labelObjectId;
      if (label === "Insurance") {
        query.columnName = columnName;
      } else {
        query.columnName = "facility";
      }
    }

    dispatch({
      type: "SET_LOADING_PATIENTS",
      value: true,
    });

    parseAction("post", config.BASE_URL + "/parse/functions/getPatients", query, {
      "Content-Type": "application/json",
    })
      .then((results) => {
        let tempGroup = [...results.result.circles];

        dispatch({
          type: "SET_LOADING_PATIENTS",
          value: false,
        });

        if (label) {
          let sortedGroup = sort(tempGroup).asc((p) => p.name.toLowerCase());
          dispatch({
            type: LABEL_ITEM_STATUS,
            label: label,
            labelObjectId: labelObjectId,
            isFetching: false,
            groups: sortedGroup,
          });
        } else {
          if (willLoadMore) {
            let sortedGroup = sort(tempGroup).asc((p) => p.name.toLowerCase());
            dispatch({
              type: LOAD_MORE_GROUPS,
              payload: sortedGroup,
              groupType: groupType,
            });
          } else {
            let sortedGroup = sort(tempGroup).asc((p) => p.name.toLowerCase());
            dispatch({
              type: FETCH_GROUPS,
              payload: sortedGroup,
              status: "SUCCESS",
              groupType: groupType,
            });
          }
        }

        resolve(tempGroup);
      })
      .catch((error) => {
        if (!willLoadMore) {
          dispatch({
            type: FETCH_GROUPS,
            status: "FAILED",
            groupType: groupType,
          });
        }

        dispatch({
          type: "SET_LOADING_PATIENTS",
          value: false,
        });

        reject(error);
      });
  });
};

export const searchGroups = ({ text = "", page = 0 }) => async (dispatch) => {
  let value = text;

  if (typeof text === "string") {
    value = value.toLowerCase().trim();
  }

  dispatch({
    type: "START_LOADING_GROUP_SEARCH",
    initialLoad: page === 0,
  });

  const res = await parseAction("post", config.BASE_URL + "/parse/functions/searchGroupsV2", {
    search: value,
    page,
  });

  dispatch({
    type: FILTER_GROUPS,
    results: res.result.circles,
    text: text,
    page: res.result.page,
    pageSize: res.result.pageSize,
    hasMore: res.result.hasMore,
  });
};

export const searchPatients = (text, newPage = 0) => (dispatch) => {
  return parseAction("post", config.BASE_URL + "/parse/functions/searchPatients:v3", {
    search: text,
    limit: 30,
    page: newPage,
  })
    .then((res) => {
      const { circles, page, pageSize, hasMore } = res.result;

      dispatch({
        type: FILTER_PATIENTS,
        results: circles,
        text: text,
        loadMore: newPage > 0,
        pagination: {
          page,
          pageSize,
          hasMore,
        },
      });
    })
    .catch((err) => {
      debugger;
      return Promise.reject(err);
    });
};

export const searchStatus = (isSearching = false) => (dispatch) => {
  dispatch({
    type: SEARCHING_STATUS,
    isSearching: isSearching,
  });
};

export const loadMoreGroups = (skip) => (dispatch) => {
  new Promise(() => {});
};

export const getGroups = () => (dispatch) => {
  dispatch({
    type: GET_GROUPS,
  });
};

export const getPatients = () => (dispatch) => {
  dispatch({
    type: GET_PATIENTS,
  });
};

/**
 * Filter group base on user search
 * @param {String} text
 */
export const filterGroups = (text) => (dispatch) => {
  dispatch({
    type: FILTER_GROUPS,
    text: text,
  });
};

export const removeMember = (contactObjectId, uuid) => async (dispatch) => {
  const res = await parseAction("post", config.BASE_URL + "/parse/functions/removeCircleMember", {
    contactObjectId: contactObjectId,
    circleUUID: uuid,
  });

  return res.result;
};

export const newGroup = (group) => (dispatch) => {
  dispatch({
    type: NEW_GROUP,
    groupType: group.groupType,
    group: group,
  });
};

// FIXME change cloud function to addMember
// userObjectId = []
// circleObjectId
// isAdmin - if auto admin

export const inviteMember = (user, circleUUID) => (dispatch) => {
  return parseAction("post", config.BASE_URL + "/parse/functions/addCircleMember", {
    objectId: user.objectId,
    username: user.username,
    circleUUID: circleUUID,
  }).then((res) => res.result);
};

export const assignAdmin = (userObjectId, circleObjectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("post", config.BASE_URL + "/parse/functions/addAdminToCircle", {
      contactObjectId: userObjectId,
      circleObjectId: circleObjectId,
    })
      .then((req) => {
        resolve(req.result);
      })
      .catch((error) => {});
  });
};

export const removeAdmin = (userObjectId, circleObjectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("post", config.BASE_URL + "/parse/functions/removeAdminFromCircle", {
      contactObjectId: userObjectId,
      circleObjectId: circleObjectId,
    }).then((req) => {
      resolve(req.result);
    });
  });
};

export const deleteGroup = (circleObjectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("post", config.BASE_URL + "/parse/functions/deleteCircle", {
      circleObjectId: circleObjectId,
    })
      .then((req) => {
        dispatch({
          type: REMOVE_GROUP,
          objectId: circleObjectId,
        });
        resolve(req.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const leaveGroup = (circleObjectId, contactObjectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("post", config.BASE_URL + "/parse/functions/removeCircleMember", {
      circleObjectId: circleObjectId,
      contactObjectId: contactObjectId,
    })
      .then((req) => {
        dispatch({
          type: REMOVE_GROUP,
          objectId: circleObjectId,
        });
        resolve(req.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateGroupName = (name, groupObjectId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("put", config.BASE_URL + "/parse/classes/Circle/" + groupObjectId, {
      name: name,
      nameLower: name.toLowerCase(),
    })
      .then((group) => {
        dispatch({
          type: GROUP_NAME_UPDATED,
          groupObjectId: groupObjectId,
          name: name,
        });
        resolve(group);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updatePatientInfo = (patientInfo, objectId) => (dispatch) => {
  return updatePatient(patientInfo, objectId, dispatch);
};

const updatePatient = (patientInfo, objectId, dispatch, columnName) => {
  return new Promise((resolve, reject) => {
    let Circle = Parse.Object.extend("Circle");
    let query = new Parse.Query(Circle);

    query.include("facility");
    query.include("primaryInsurance");
    query.include("secondaryInsurance");
    query.include("otherInsurance");
    query.include("serviceLocation");
    query.get(objectId).then(
      (circle) => {
        for (let key in patientInfo) {
          if (key === "serviceLocation") {
            let ServiceLocation = Parse.Object.extend("ServiceLocation");
            let serviceLocation = new ServiceLocation();
            serviceLocation.id = patientInfo.serviceLocation.objectId;
            circle.set(key, serviceLocation);
          } else if (key === "primaryInsurance" || key === "secondaryInsurance" || key === "otherInsurance") {
            let Insurance = Parse.Object.extend("Insurance");
            let insurance = new Insurance();
            insurance.id = patientInfo[columnName].objectId;
            circle.set(key, insurance);
          } else if (key === "facility") {
            let Facility = Parse.Object.extend("Facility");
            let facility = new Facility();
            facility.id = patientInfo.facility.objectId;
            circle.set(key, facility);
          } else if (patientInfo[key] === "") {
            circle.unset(key);
          } else {
            if (key === "firstName") {
              circle.set("firstNameLower", patientInfo[key].toLowerCase());
            }
            if (key === "lastName") {
              circle.set("lastNameLower", patientInfo[key].toLowerCase());
            }
            circle.set(key, patientInfo[key]);
          }
        }

        let name = patientInfo.lastName + ", " + patientInfo.firstName;
        circle.set("name", name);
        circle.set("nameLower", name.toLowerCase());

        circle.save().then(
          (res) => {
            dispatch({
              type: GROUP_NAME_UPDATED,
              groupObjectId: objectId,
              name: name,
            });
            let _results = JSON.stringify(res);
            _results = JSON.parse(_results);

            if (patientInfo.facility) _results.facility = patientInfo.facility;
            if (patientInfo.insurance) _results.insurance = patientInfo.insurance;
            if (patientInfo.serviceLocation) _results.serviceLocation = patientInfo.serviceLocation;

            resolve(_results);
          },
          (error) => {
            reject(new Error(error.message));
          }
        );
      },
      (error) => {
        reject(new Error(error.message));
      }
    );
  });
};

export const createFacility = (facilityName, group) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("get", config.BASE_URL + "/parse/classes/Facility", {
      where: {
        nameLower: facilityName.toLowerCase(),
      },
    })
      .then((res) => {
        if (res.results.length === 0) {
          createNewFacility(facilityName)
            .then((facility) => {
              let _facility = JSON.stringify(facility);
              _facility = JSON.parse(_facility);
              let patientInfo = {
                facility: {
                  ..._facility,
                },
                firstName: group.firstName,
                lastName: group.lastName,
              };

              updatePatient(patientInfo, group.objectId, dispatch)
                .then((res) => {
                  resolve(res);
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          let patientInfo = {
            facility: {
              ...res.results[0],
            },
            firstName: group.firstName,
            lastName: group.lastName,
          };

          updatePatient(patientInfo, group.objectId, dispatch)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createNewFacility = (facilityName) => {
  return new Promise((resolve, reject) => {
    let Facility = Parse.Object.extend("Facility");
    let facility = new Facility();

    facility.set("name", facilityName);
    facility.set("nameLower", facilityName.toLowerCase());

    facility.save().then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(new Error(error));
      }
    );
  });
};

const createNewInsurace = (insuranceName) => {
  return new Promise((resolve, reject) => {
    let Insurance = Parse.Object.extend("Insurance");
    let insurance = new Insurance();

    insurance.set("name", insuranceName);
    insurance.set("nameLower", insuranceName.toLowerCase());

    insurance.save().then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(new Error(error));
      }
    );
  });
};

export const createInsurance = (insuranceName, group, columnName) => (dispatch) => {
  return parseAction("get", config.BASE_URL + "/parse/classes/Insurance", {
    where: {
      nameLower: insuranceName.toLowerCase(),
    },
  }).then((res) => {
    if (res.results.length === 0) {
      return createNewInsurance(insuranceName, columnName).then((insurance) => {
        let _insurance = JSON.stringify(insurance);
        _insurance = JSON.parse(_insurance);

        let patientInfo = {
          [columnName]: {
            ..._insurance,
          },
          firstName: group.firstName,
          lastName: group.lastName,
        };

        return updatePatient(patientInfo, group.objectId, dispatch, columnName);
      });
    } else {
      let patientInfo = {
        [columnName]: {
          ...res.results[0],
        },
        firstName: group.firstName,
        lastName: group.lastName,
      };

      return updatePatient(patientInfo, group.objectId, dispatch, columnName);
    }
  });
};

const createNewInsurance = (insuranceName, columnName) => {
  return new Promise((resolve, reject) => {
    let Insurance = Parse.Object.extend("Insurance");
    let insurance = new Insurance();

    insurance.set("name", insuranceName);
    insurance.set("nameLower", insuranceName.toLowerCase());
    insurance.set("columnName", columnName);

    insurance.save().then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(new Error(error));
      }
    );
  });
};

export const createServiceLocation = (serviceLocationName, group) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("get", config.BASE_URL + "/parse/classes/ServiceLocation", {
      where: {
        nameLower: serviceLocationName.toLowerCase(),
      },
    })
      .then((res) => {
        if (res.results.length === 0) {
          createNewServiceLocation(serviceLocationName)
            .then((serviceLocation) => {
              let _serviceLocation = JSON.stringify(serviceLocation);
              _serviceLocation = JSON.parse(_serviceLocation);
              let patientInfo = {
                serviceLocation: {
                  ..._serviceLocation,
                },
                firstName: group.firstName,
                lastName: group.lastName,
              };

              updatePatient(patientInfo, group.objectId, dispatch)
                .then((res) => {
                  resolve(res);
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          let patientInfo = {
            serviceLocation: {
              ...res.results[0],
            },
            firstName: group.firstName,
            lastName: group.lastName,
          };

          updatePatient(patientInfo, group.objectId, dispatch)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createNewServiceLocation = (serviceLocationName) => {
  return new Promise((resolve, reject) => {
    let ServiceLocation = Parse.Object.extend("ServiceLocation");
    let serviceLocation = new ServiceLocation();

    serviceLocation.set("name", serviceLocationName);
    serviceLocation.set("nameLower", serviceLocationName.toLowerCase());

    serviceLocation.save().then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(new Error(error));
      }
    );
  });
};

export const searchSuggestFacility = (typeKey) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("get", config.BASE_URL + "/parse/classes/Facility", {
      name: {
        $regex: typeKey,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateFacility = (facilityObjectId, newName) => (dispatch) => {
  return new Promise((resolve, reject) => {
    parseAction("put", config.BASE_URL + "/parse/classes/Facility/" + facilityObjectId, {
      name: newName,
    })
      .then((_facility) => {
        resolve(_facility);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createPatientFacility = (facility, circle, organization) => {
  return new Promise((resolve, reject) => {
    let PatientFacility = Parse.Object.extend("PatientFacility");
    let patientFacility = new PatientFacility();

    let Facility = Parse.Object.extend("Facility");
    let _facility = new Facility();
    _facility.id = facility.objectId;

    let Circle = Parse.Object.extend("Circle");
    let _circle = new Circle();
    _circle.id = circle.objectId;

    let Organization = Parse.Object.extend("Organization");
    let _organization = new Organization();
    _organization.id = organization.objectId;

    patientFacility.set("facility", _facility);
    patientFacility.set("circle", _circle);
    patientFacility.set("organization", _organization);

    patientFacility.save().then(
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(new Error(error));
      }
    );
  });
};

export const createForwardedMessage = (message) => (dispatch) => {
  return new Promise((resolve, reject) => {
    // Create the object.
    let createAttachment = false;
    var ForwardedMessage = Parse.Object.extend("ForwardedMessage");
    var forwardedMessage = new ForwardedMessage();

    let sender = "";
    if (message.user.displayName) {
      sender = message.user.displayName;
    } else {
      if (message.user.objectId === Parse.User.current().id) {
        sender = Parse.User.current().get("displayName");
      }
    }

    let date = moment(message.createdAt).format("L LT");
    let forwardText = 'Fwd: "' + message.text + '"  ----------- ' + sender + " - " + date;

    if (message.text !== "") {
      forwardedMessage.set("text", forwardText);
    }

    if (message.attachments) {
      let newAttachment = [
        {
          __type: "Pointer",
          className: "Attachment",
          objectId: message.attachments[0].objectId,
        },
      ];
      forwardedMessage.set("attachments", newAttachment);
    }

    forwardedMessage.set("user", Parse.User.current());
    forwardedMessage.set("messageObjectId", message.objectId);
    forwardedMessage.set("circle", message.circle);
    forwardedMessage.set("originalCreatedAt", new Date(message.createdAt));

    forwardedMessage.save().then(
      (res) => {
        var Message = Parse.Object.extend("Message");
        var _message = new Parse.Query(Message);
        _message.get(message.objectId).then(
          (messageObject) => {
            messageObject.set("forwardedToCCM", true);
            messageObject.save().then(
              (gameScore) => {
                dispatch({
                  type: BEEN_FORWARDED_TO_CCM,
                  objectId: message.objectId,
                  threadId: message.circle.objectId,
                });
              },
              (error) => {}
            );
          },
          (error) => {
            // The object was not retrieved successfully.
            // error is a Parse.Error with an error code and message.
          }
        );
      },
      (error) => {
        // FIXME notif for unsuccessfull archive(delete)
        console.log(error);
      }
    );
  });
};

export const UUID = () => {
  let d = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16).toUpperCase();
  });
  return uuid;
};

const createAttachmentObject = (parseFile, file) => {
  // TODO create attachment file
  return new Promise((resolve, reject) => {
    let Attachment = Parse.Object.extend("Attachment");
    let newAttachment = new Attachment();

    const uuid = UUID();
    let fileType = file.type;
    if (fileType === "application/pdf") fileType = "file/pdf";

    // newAttachment.set("fileDuration", fileDuration);
    newAttachment.set("fileType", fileType);
    newAttachment.set("file", parseFile);
    newAttachment.set("attachmentUUID", uuid);
    // "This message contains an audio file. Update Hubchart to the latest version to play the message."

    newAttachment.save().then(
      (_newAttachment) => {
        resolve(_newAttachment);
      },
      (error) => {
        reject(new Error("Failed Send"));
      }
    );
  });
};

export const fetchInsuranceProviders = () => async (dispatch) => {
  const insuranceProviders = await InsuranceApi.getProviders();

  dispatch({
    type: "SAVE_INSURANCE_PROVIDERS",
    items: insuranceProviders,
  });
};

export const getMembers = (circleObjectId) => async (dispatch) => {
  dispatch({
    type: "SET_LOADING_MEMBERS",
    isLoading: true,
  });
  const url = config.BASE_URL + "/api/circle-members/" + circleObjectId;
  const params = {  };

  try {
    const res = await parseAction("get", url, params);

    dispatch({
      type: "SET_LOADING_MEMBERS",
      isLoading: false,
    });

    return res.result;
  } catch (err) {
    dispatch({
      type: "SET_LOADING_MEMBERS",
      isLoading: false,
    });

    throw err;
  }
};

export const setSideNavTab = (tab) => (dispatch) => {
  dispatch({
    type: SET_SIDE_NAV_TAB,
    activeSideNavTab: tab,
  });
};

export const setLoadingMembers = (isLoading) => (dispatch) => {
  dispatch({
    type: "SET_LOADING_MEMBERS",
    isLoading: isLoading,
  });
}