import React, { Component } from "react";
import Parse from "parse";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { parseAction } from "../../../actions/REST";
import {
  removeMember,
  inviteMember,
  assignAdmin,
  leaveGroup,
  deleteGroup,
  updateGroupName,
  updatePatientInfo,
  getMembers,
  setLoadingMembers,
} from "../../../actions/GroupAction";
import { setActiveThread, removeThread, updateNotifSettings } from "../../../actions/ThreadAction";
import Member from "./Member";
import LoadingSpinner from "../Bubble/LoadingSpinner";
import AddMemberModal from "./AddMemberModal";
import GroupWarningModal from "./GroupWarningModal";
import config from "../../../config";
import Switch from "react-switch";

const $ = window.$;
export class Group extends Component {
  constructor() {
    super();
    this.state = {
      groupObject: "",
      activeThread: "",
      isFetchingGroupMembers: false,
      fetchGroupMembersResult: "",
      isFetching: false,
      currentUserIsAdmin: false,
      willEditGroupName: false,
      openAddMemberModal: false,
      openWarningModal: false,
      nameIsUpdating: false,
      failedToUpdateName: false,
      notificationType: 0,
      firstName: "",
      lastName: "",
    };

    this.openAddMemberModal = this.openAddMemberModal.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.assignAdmin = this.assignAdmin.bind(this);
    this.openWarningModal = this.openWarningModal.bind(this);
    this.confirmAction = this.confirmAction.bind(this);
    this.updateGroupName = this.updateGroupName.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.state.activeThread.threadId !== this.props.thread.threadId) {
      const { thread = {} } = this.props;
      const { notificationType = 0 } = thread;

      this.setState({
        activeThread: this.props.thread,
        isFetching: true,
        currentUserIsAdmin: false,
        fetchGroupMembersResult: "",
        groupObject: "",
        notificationType,
      });
      this.fetchMembers(this.props.thread.threadId);
    } else {
      /**
       * We will refetch for error results
       */
      if (this.state.fetchGroupMembersResult === "error") {
        const { thread = {} } = this.props;
        const { notificationType = 0 } = thread;

        this.setState({
          fetchGroupMembersResult: "",
          activeThread: this.props.thread,
          isFetching: true,
          currentUserIsAdmin: false,
          groupObject: "",
          notificationType,
        });
        this.fetchMembers(this.props.thread.threadId);
      }
    }
    return null;
  }

  componentDidUpdate() {}

  fetchMembers(groupObjectId) {
    this.props.setLoadingMembers(true);

    const method = "get";
    const url = config.BASE_URL + "/api/circle-members/" + groupObjectId;
    const data = {};

    parseAction(method, url, data)
      .then((response) => {
        const { regMembers = [], administrators = [] } = response.result;
        this.props.setLoadingMembers(false);

        if (!response.result) {
          this.setState({
            isFetching: false,
          });
          return;
        }
        this.setState({
          isAvailable: response.result.includedInOwnersOrganization,
        });
        let currentUserIsAdmin = false;
        for (let x = 0; x < administrators.length; x++) {
          regMembers.forEach((user, index) => {
            if (user.objectId === administrators[x].objectId) {
              if (regMembers[index]) {
                if (regMembers[index].objectId === Parse.User.current().id) {
                  currentUserIsAdmin = true;
                }
              }
              regMembers[index].isAdministrator = true;
            }
          });
        }

        if (groupObjectId === this.state.activeThread.threadId) {
          this.setState({
            firstName: response.result.firstName,
            lastName: response.result.lastName,
            isFetching: false,
            groupObject: response.result,
            fetchGroupMembersResult: "success",
            currentUserIsAdmin: currentUserIsAdmin,
          });
          this.props.saveGroupMembers(groupObjectId, response.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchGroupObject(groupObjectId) {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: groupObjectId,
      },
    })
      .then((result) => {
        // Check if result is empty
        if (result.results.length === 0) {
          this.setState({
            isFetching: false,
          });
          return;
        }
        var group = result.results[0];
        group.members = []; //Set members as empty array

        this.setState({
          isAvailable: group.includedInOwnersOrganization,
        });

        this.getMembers(group)
          .then((result) => {
            let currentUserIsAdmin = false;
            for (let x = 0; x < group.administrators.length; x++) {
              result.regMembers.forEach((user, index) => {
                if (user.objectId === group.administrators[x].objectId) {
                  if (result.regMembers[index]) {
                    if (result.regMembers[index].objectId === Parse.User.current().id) {
                      currentUserIsAdmin = true;
                    }
                  }
                  result.regMembers[index].isAdministrator = true;
                }
              });
            }

            if (groupObjectId === this.state.activeThread.threadId) {
              group.members = result.regMembers;
              group.orgAdmins = result.orgAdmins;
              group.orgMembers = result.orgMembers;
              this.setState({
                firstName: group.firstName,
                lastName: group.lastName,
                isFetching: false,
                groupObject: group,
                fetchGroupMembersResult: "success",
                currentUserIsAdmin: currentUserIsAdmin,
              });
              this.props.saveGroupMembers(groupObjectId, result);
            }
          })
          .catch((error) => {
            this.setState({
              isFetching: false,
              groupObject: group,
              currentUserIsAdmin: false,
              fetchGroupMembersResult: "error",
            });
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getMembers(group, callback) {
    return this.props.getMembers(group.objectId);
  }

  /**
   *
   */
  removeMember(userObjectId) {
    this.props
      .removeMember(userObjectId, this.state.groupObject.uuid)
      .then((circleMember) => {
        let tempMembers = [...this.state.groupObject.members.filter((user) => user.objectId !== userObjectId)];
        let tempGroupObject = {
          ...this.state.groupObject,
          members: tempMembers,
        };

        this.setState({
          groupObject: tempGroupObject,
        });

        this.props.saveRegularMembers(tempGroupObject.objectId, tempMembers);
      })
      .catch(() => {});
  }

  /**
   *
   */
  inviteMember(toBeInvited) {
    toBeInvited.map((contact) => {
      this.props
        .inviteMember(contact, this.state.groupObject.uuid)
        .then((circleMember) => {
          let tempMembers = [...this.state.groupObject.members, contact];
          let tempGroupObject = {
            ...this.state.groupObject,
            members: tempMembers,
          };
          this.setState({
            groupObject: tempGroupObject,
          });

          this.props.saveRegularMembers(tempGroupObject.objectId, tempMembers);
        })
        .catch((error) => {
          //FIXME error when inviting member
        });
    });
  }

  /**
   *
   */
  assignAdmin(member) {
    this.props
      .assignAdmin(member.objectId, this.props.thread.threadId)
      .then((circle) => {
        let tempMembers = [
          ...this.state.groupObject.members.map((data, i) => {
            if (data.objectId === member.objectId) {
              return {
                ...data,
                isAdministrator: true,
              };
            }
            return data;
          }),
        ];

        let tempGroupObject = {
          ...this.state.groupObject,
          administrators: circle.administrators,
          members: tempMembers,
        };
        this.setState({
          groupObject: tempGroupObject,
        });

        this.props.saveRegularMembers(tempGroupObject.objectId, tempMembers);
      })
      .catch((error) => {
        //FIXME  error when assigning member to admin
      });
  }

  /**
   *
   */
  revokeAdmin() {}

  willEditGroupName() {
    this.setState({
      willEditGroupName: true,
    });
  }

  openAddMemberModal() {
    this.setState({
      openAddMemberModal: true,
    });
  }

  closeAddMemberModal() {
    this.setState({
      openAddMemberModal: false,
    });
  }

  confirmAction(isCreator) {
    if (isCreator) {
      // if creator it means that user want to delete the group
      this.props.deleteGroup(this.state.groupObject.objectId).then(() => {
        this.props.setActiveThread("");
        this.props.removeThread(this.state.groupObject.objectId);
        this.props.handleCloseNav();
        // this.props.history.push('/');
      });
    } else {
      this.props.leaveGroup(this.state.groupObject.objectId, Parse.User.current().id).then(() => {
        this.props.setActiveThread("");
        this.props.removeThread(this.state.groupObject.objectId);
        this.props.handleCloseNav();
        // this.props.history.push('/');
      });
    }
  }

  closeModal() {
    this.setState({
      openWarningModal: false,
    });
  }

  openWarningModal() {
    this.setState({
      openWarningModal: true,
    });
  }

  updateGroupName(e) {
    e.preventDefault();

    const { firstName, lastName, groupObject } = this.state;

    if (!firstName || !lastName) {
      return;
    }

    this.setState({ isLoading: true });

    this.props
      .updatePatientInfo({ firstName, lastName }, groupObject.objectId)
      .then(() => {
        this.setState({
          nameIsUpdating: false,
          willEditGroupName: false,
          failedToUpdateName: false,
          groupObject: {
            ...groupObject,
            firstName,
            firstNameLower: firstName.toLowerCase(),
            lastName,
            lastNameLower: lastName.toLowerCase(),
          },
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          failedToUpdateName: true,
          nameIsUpdating: false,
          willEditGroupName: false,
          isLoading: false,
        });
      });
  }

  handleChange(e) {
    const { activeThread } = this.state;
    const notificationType = Number(e.target.value);

    this.setState({
      notificationType,
    });

    this.props.updateNotifSettings(activeThread.objectId, notificationType);
  }

  handleInputChange(e, key) {
    this.setState({
      [key]: e.target.value,
    });
  }

  cancelEdit(e) {
    this.setState({ willEditGroupName: false });
  }

  async handleChangeCorporate(checked) {
    this.setState({
      isAvailable: checked,
    });

    const { groupObject } = this.state;

    this.setState({ isLoadingCorporate: true });

    await this.props.updatePatientInfo({ includedInOwnersOrganization: checked }, groupObject.objectId);

    this.setState({ isLoadingCorporate: false });
  }

  render() {
    const group = this.state.groupObject;

    const { firstName, lastName, isLoading } = this.state;

    if (group === "") return null;

    let canEditName = false;
    let currentUserObjectId = Parse.User.current().id;
    if (group.owner.objectId === currentUserObjectId) {
      canEditName = true;
    }

    let _hasUser = group.administrators.find((c) => c.objectId === currentUserObjectId);
    if (_hasUser) canEditName = true;

    let imageURL = require("../../../assets/images/group-default.png");
    if (typeof group.image !== "undefined") {
      imageURL = group.image.url;
    }

    let isCreator = group.owner.objectId === Parse.User.current().id;
    // const Members = group.members.map((data) => {
    //   return (
    //     <Member
    //       key={data.objectId}
    //       member={data}
    //       isCreator={isCreator}
    //       isAdmin={this.state.currentUserIsAdmin}
    //       removeMember={this.removeMember}
    //       assignAdmin={this.assignAdmin}
    //     />
    //   );
    // });

    const { notificationType } = this.state;

    return (
      <div className="setting-container">
        {this.state.isFetching && <LoadingSpinner />}
        <div className="img-container">
          <img src={imageURL} alt="Group Profile Picture" />
          <div className="Form-Container" style={{ margin: "0" }}>
            <form onSubmit={this.updateGroupName} style={{ position: "relative" }}>
              {this.state.willEditGroupName ? (
                <>
                  <PatientField
                    onChange={(e) => this.handleInputChange(e, "lastName")}
                    label="Last name"
                    value={lastName}
                    placeholder="Last name"
                    iconClass="fas fa-user"
                  />
                  <PatientField
                    onChange={(e) => this.handleInputChange(e, "firstName")}
                    label="First name"
                    value={firstName}
                    placeholder="First name"
                    iconClass="fas fa-user"
                  />
                  {isLoading ? (
                    <button disabled className="btn btn-primary hc-bg-primary mr-2">
                      <i className="fa fa-spinner mr-1"></i>
                      Saving
                    </button>
                  ) : (
                    <>
                      <button className="btn btn-primary hc-bg-primary mr-2">
                        <i className="fa fa-save mr-1"></i>
                        Save
                      </button>
                      <button onClick={(e) => this.cancelEdit(e)} type="button" className="btn btn-light">
                        Cancel
                      </button>
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              {!this.state.willEditGroupName && (
                <div>
                  <p style={{ padding: "0" }}>
                    {group.lastName.toUpperCase()}, {group.firstName.toUpperCase()}
                  </p>
                  {canEditName && (
                    <span onClick={this.willEditGroupName.bind(this)} className="hc-primary-text">
                      <i className="fa fa-edit mr-1"></i>
                      Edit Name
                    </span>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
        {this.state.currentUserIsAdmin && (
          <div className="detail-container">
            <label>
              <span className="text-primary mr-1" style={{ fontSize: "14px" }}>
                Corporate access
              </span>
              <Switch
                disabled={this.state.isLoadingCorporate}
                onChange={(e) => this.handleChangeCorporate(e)}
                checked={this.state.isAvailable}
                onColor="#b6e0ff"
                onHandleColor="#2693e6"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={30}
                className="react-switch ml-1 shift-down-4"
                id="material-switch"
              />
            </label>
          </div>
        )}
        <div className="detail-container">
          <h5 className="hc-primary-text">Details</h5>
          <p>{group.groupDescription}</p>
        </div>
        <div className="detail-container">
          <h5 className="hc-primary-text">Group Notifications</h5>
          <div className="radio">
            <label className={notificationType === 0 ? "hc-primary-text" : ""}>
              <input
                className="mr-1"
                type="radio"
                name="optradio"
                value="0"
                checked={notificationType === 0}
                onChange={this.handleChange}
              />
              <i className="fa fa-exclamation-triangle mr-1"></i>
              Important Messages Only
            </label>
          </div>
          <div className="radio">
            <label className={notificationType === 1 ? "hc-primary-text" : ""}>
              <input
                className="mr-1"
                type="radio"
                name="optradio"
                value="1"
                checked={notificationType === 1}
                onChange={this.handleChange}
              />
              <i className="fa fa-bell mr-1"></i>
              Notify All
            </label>
          </div>
          <div className="radio disabled">
            <label className={notificationType === 2 ? "hc-primary-text" : ""}>
              <input
                className="mr-1"
                type="radio"
                name="optradio"
                value="2"
                checked={notificationType === 2}
                onChange={this.handleChange}
              />
              <i className="fa fa-bell-slash mr-1"></i>
              <span>Mute All Notifications</span>
            </label>
          </div>
        </div>
        <div className="detail-container" style={{ marginTop: "15px", paddingTop: "20px" }}>
          {isCreator ? (
            <button
              onClick={this.openWarningModal}
              className="btn btn-hubchart btn-hubchart-danger"
              style={{ width: "100%", fontSize: "18px" }}
            >
              DELETE GROUP
            </button>
          ) : (
            <button
              onClick={this.openWarningModal}
              className="btn btn-hubchart btn-hubchart-danger"
              style={{ width: "100%", fontSize: "18px" }}
            >
              LEAVE GROUP
            </button>
          )}
        </div>

        {this.state.openAddMemberModal && (
          <AddMemberModal
            members={group.members}
            inviteMember={this.inviteMember.bind(this)}
            isOpen={this.state.openAddMemberModal}
            modalClose={this.closeAddMemberModal.bind(this)}
          />
        )}
        {this.state.openWarningModal && (
          <GroupWarningModal
            isCreator={isCreator}
            isOpen={this.state.openWarningModal}
            modalClose={this.closeModal.bind(this)}
            confirmAction={() => this.confirmAction(isCreator)}
          />
        )}
      </div>
    );
  }
}

function PatientField(props) {
  const {
    /* For input */
    label,
    placeholder,
    value,

    /* Icon */
    iconClass,
    children,
  } = props;

  return (
    <div>
      <label className="hc-primary-text mb-0" style={{ fontSize: "12px" }}>
        {label}
      </label>
      <div class="input-group mb-2">
        <div class="input-group-prepend">
          <span
            class="input-group-text hc-input-addon d-flex flex-column justify-content-center"
            style={{ fontSize: "16px" }}
          >
            <i className={iconClass}></i>
          </span>
        </div>
        {/* You can provide custom input */}
        {children ? (
          { ...children }
        ) : (
          <input
            onChange={props.onChange}
            value={value}
            type="text"
            class="form-control pl-0"
            style={{ textTransform: "uppercase" }}
            placeholder={placeholder}
          />
        )}
      </div>
    </div>
  );
}

function PatientValue(props) {
  const {
    /* For input */
    label,
    value,

    /* Icon */
    iconClass,
  } = props;

  return (
    <div class="input-group" style={{ boxShadow: "unset" }}>
      <div class="input-group-prepend">
        <span class="input-group-text hc-input-addon">
          <i className={iconClass}></i>
          &nbsp;
          {label}
        </span>
      </div>
      <div className="center-vertical">{value ? value : <span className="text-blank">none</span>}</div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, {
    updateGroupName,
    removeMember,
    inviteMember,
    assignAdmin,
    leaveGroup,
    deleteGroup,
    setActiveThread,
    removeThread,
    updateNotifSettings,
    updatePatientInfo,
    getMembers,
    setLoadingMembers,
  })(Group)
);
