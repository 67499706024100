import React, { Component } from 'react'
import moment from 'moment'

export class TimeMessage extends Component {

    beautifyTime(date) {
        let today = moment(new Date());
        let yesterday =  moment().subtract(1, 'day');
        let dateSend = moment(new Date(date));
        if (typeof date === "undefined") {
        	return;
        }


        // TODAY
        if(moment(dateSend).isSame(today, 'day')) {
            // return moment(dateSend).format('LT');
            return "Today";
        } else if(moment(dateSend).isSame(yesterday, 'day')) { // YESTERDAY
            // return "Yesterday " + moment(dateSend).format('LT');
            return "Yesterday";
        // } else if(moment(dateSend).isSame(today, 'week')) { // SAME WEEK
        //     return moment(dateSend).format('ddd LT').toUpperCase();
        // } else if(moment(dateSend).isSame(today, 'month')) { // SAME MONTH
        //     return moment(new Date(date)).format('ddd, MMM D LT').toUpperCase();
        } else if(moment(dateSend).isSame(today, 'year')) { // SAME YEAR
            return moment(new Date(date)).format('ddd, MMM DD');
        } else {
            return moment(new Date(date)).format('MMM DD, YYYY');
        }


    }

    render() {
        const { message } = this.props;

        return (
            <li className="Bubble-List-Container">
				<div className="_time-message">
            	    {this.beautifyTime(message.dateTime)}
            	</div>
                {/* <div className="_time-message" style={{display: "flex"}}>
                    <button className="btn btn-sm btn-primary">
                    <i style={{ margin: "5px" }} class="fad fa-sm fa-angle-double-down" ></i>
                    <span>Load more messages</span>
                    <i style={{ margin: "5px" }} class="fad fa-sm fa-angle-double-down" ></i>
                    </button>
            	</div> */}
           	</li>
        )
    }
}

export default TimeMessage
