import {
  SET_ACTIVE_TAB,
  NEW_ACTIVE_THREAD,
  CREATE_THREAD,
  FETCH_CONVERSATION_MESSAGES,
  OPEN_CLOSE_CHECKBOXES,
  MESSAGES_TO_FORWARD,
  FETCH_CONVERSATION_STATUS,
  SEND_NEW_MESSAGE,
  SEND_MESSAGE_STATUS,
  SAVE_THREADS,
  GET_THREADS,
  GET_THREADS_RECENT,
  FILTER_MESSAGES,
  RECEIVE_MESSAGE,
  RECENT_UPDATED,
  ARCHIVE_MESSAGE,
  REMOVE_THREAD,
  UPDATE_A_MESSAGE,
  SET_DRAFT_MESSAGE,
  SET_DEFAULT_DRAFT_MESSAGES,
  SELECT_UNSELECT_MESSAGES,
  IS_FETCHING_SELECTED_THREAD,
  SELECTED_THREAD,
  TO_DOS_LOADING,
  SET_TO_DOS_ALL,
  SET_TO_DOS_ALL_HIGH,
  SET_TO_DOS_ALL_MED,
  SET_TO_DOS_ALL_ROUTINE,
  SET_TO_DOS_PENDING,
  SET_TO_DOS_PENDING_HIGH,
  SET_TO_DOS_PENDING_MED,
  SET_TO_DOS_PENDING_ROUTINE,
  SET_TO_DOS_DONE,
  SET_TO_DOS_DONE_HIGH,
  SET_TO_DOS_DONE_MED,
  SET_TO_DOS_DONE_ROUTINE,
  SET_TO_DOS_RECENT,
  SET_TO_DOS_CRITICAL,
  IS_SENDING_MESSAGE,
  HAS_MORE_TO_DOS,
  REMOVE_THREAD_UNREAD,
  SET_PREV_OPENED_THREAD,
  SET_ATTENTIONED_MESSAGE,
  SET_MESSAGES_SHOWN,
  SET_MESSAGES_BADGES,
  SET_ACTIVITY_LIST,
  SET_RICH_TEXT,
} from "./types";
import { getConferenceCalls, setConferenceTab } from "./ConferenceAction";
import { seenMessage } from "./SeenAction";
import { parseAction } from "./REST";
import Parse from "parse";
import moment from "moment";
import sort from "fast-sort";
import PUBNUB from "pubnub";
import config from "../config";
import util from "../helper/util";
import messageApi from "../api/Message";
import { assign, first, stubArray } from "lodash";
import { useState } from "react";

export const markAllThreadsAsRead = () => (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/markAllThreadsAsRead";

  const options = {};

  return parseAction(method, url, options)
    .then((response) => {
      dispatch({
        type: "SET_UNREAD_COUNT",
        value: 0,
      });
    })
    .catch((error) => {
      // TODO handle error when fetching thread
      console.error(error);
    });
};

export const deleteAllPriorities = () => (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/deleteAllPrioritiesV2";

  const options = {};

  return parseAction(method, url, options)
    .then((response) => {
      dispatch({
        type: "CLEAR_PRIORITIES",
      });
    })
    .catch((error) => {
      // TODO handle error when fetching thread
      console.error(error);
    });
};

export const deletePriority = (priorityId) => (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/deletePriority";

  const options = {
    objectId: priorityId,
  };

  dispatch({
    type: "DELETE_PRIORITY",
    priorityId,
  });

  return parseAction(method, url, options);
};

export const deletePriorities = (priorityIds = []) => (dispatch) => {
  dispatch({
    type: "DELETE_PRIORITIES",
    priorityIds,
  });

  const method = "post";
  const url = config.BASE_URL + "/parse/functions/deletePriorities";

  const options = {
    objectIds: priorityIds,
  };

  return parseAction(method, url, options, {
    "Content-Type": "application/json",
  });
};

export const sortThreads = (options = {}) => (dispatch) => {
  dispatch({
    type: "SORT_THREADS",
    reverse: options.reverse,
  });
};

export const filterThreads = ({ attention }) => (dispatch) => {
  const action = attention ? fetchThreadsForMe() : fetchThreadsUnread();

  dispatch(action);

  dispatch({
    type: "FILTER_THREADS",
    attention,
  });
};

export const setMessageTab = (tab) => (dispatch) => {
  dispatch({
    type: "SET_MESSAGE_TAB",
    value: tab,
  });
};

export const setSubMessageTab = (tab) => (dispatch) => {
  dispatch({
    type: "SET_SUB_MESSAGE_TAB",
    value: tab,
  });
};

export const setDraftMessage = (threadId, message) => (dispatch) => {
  const encryptedId = `${util.encrypt(Parse.User.current().id)}_${util.encrypt(threadId)}`;
  const drafts = JSON.parse(localStorage.getItem("draftMessages") || "{}");
  let newDrafts = {
    ...drafts,
    [encryptedId]: message,
  };

  for (var key in newDrafts) {
    if (!newDrafts[key]) {
      delete newDrafts[key];
    }
  }

  localStorage.setItem("draftMessages", JSON.stringify(newDrafts));

  dispatch({
    type: SET_DRAFT_MESSAGE,
    threadId: encryptedId,
    message,
  });
};

export const setDefaultDraftMessages = (draftsObject) => (dispatch) => {
  let draftMessages = JSON.parse(draftsObject || "{}");

  dispatch({
    type: SET_DEFAULT_DRAFT_MESSAGES,
    draftMessages,
  });
};

export const randomID = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4();
};

export const UUID = () => {
  let d = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16).toUpperCase();
  });
  return uuid;
};

export const setActiveTab = (tab) => (dispatch) => {
  /* 
        When navigating to inbox, use its own action
    */
  if (tab === "message") {
    localStorage.setItem("activeTab", tab);
    return dispatch(linkToInbox());
  }

  dispatch({
    type: SET_ACTIVE_TAB,
    tab: tab,
  });

  localStorage.setItem("activeTab", tab);
};

export const initializePubNub = (recentThreads = []) => {
  return parseAction("post", config.BASE_URL + "/parse/functions/getPubNubKeys")
    .then((keys) => {
      const res = keys.result;
      const pubNub = new PUBNUB({
        publish_key: res.publishKey,
        subscribe_key: res.subscribeKey,
        ssl: window.location.protocol.toLowerCase() === "https:",
        origin: "pubsub.pubnub.com",
        uuid: Parse.User.current().id,
        presenceTimeout: 120,
        heartbeatInterval: 30,
      });

      if (recentThreads.length > 0) {
        const channels = recentThreads.map((thread) => `group_${thread.threadId}`);
        pubNub.subscribe({
          channels,
        });
      } else {
        return pubNub;
      }
    })
    .catch((error) => {
      // Parse.User.logOut().then(() => {
      //   localStorage.clear();
      //   window.location.href = "/";
      // });
    });
};

export const fetchThreads = (routeObjectId, loadMore = false, page = 0, circleLabelId = "", search = "", read = "") => (
  dispatch
) => {
  dispatch({
    type: "SET_LOADING",
    value: page === 0 ? true : false,
  });
  const method = "post";
  const url = config.BASE_URL + "/recents";

  const options = {
    page: page,
    pageSize: 15,
    read,
  };

  if (circleLabelId) {
    options.circleLabelId = circleLabelId;
  }

  if (search) {
    options.search = search;
  }

  return parseAction(method, url, options, { "Content-Type": "application/json" })
    .then((response) => {
      const { recents, hasMore } = response.result;
      dispatch({
        type: SAVE_THREADS,
        threads: recents,
        status: "SUCCESS",
        page: page,
        replaceThreads: !loadMore,
        hasMore,
      });

      dispatch({
        type: "SAVE_THREADS_UNREAD",
        fetchSuccess: true,
        threads: recents,
        hasMore,
        page,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });

      if (!search) {
        initializePubNub(recents);
      }

      if (routeObjectId && routeObjectId !== "") {
        ghostThread(routeObjectId, recents, dispatch);
      }

      return recents.length;
    })
    .catch((error) => {
      // TODO handle error when fetching thread
      console.error(error);
      dispatch({
        type: SAVE_THREADS,
        status: "FAILED",
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });
    });
};

export const fetchThreadsUnread = (page = 0, filter = "", read = "") => (dispatch) => {
  dispatch({
    type: "SET_LOADING",
    value: true,
  });

  dispatch({
    type: SET_PREV_OPENED_THREAD,
    prevOpenedThread: null,
  });

  const method = "post";
  // const url = config.BASE_URL + "/parse/functions/downloadRecentsUnreadV2"; *old api
  const url = config.BASE_URL + "/recents";

  const options = {
    page: page,
    pageSize: 15,
    filter: filter,
    read,
  };

  return parseAction(method, url, options, { "Content-Type": "application/json" })
    .then((response) => {
      const { recents, hasMore } = response.result;

      dispatch({
        type: "SAVE_THREADS_UNREAD",
        fetchSuccess: true,
        threads: recents,
        hasMore,
        page,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });
    })
    .catch((error) => {
      // TODO handle error when fetching thread
      console.error(error);

      dispatch({
        type: "SAVE_THREADS_UNREAD",
        fetchSuccess: false,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });
    });
};

export const fetchPrivateThreadCount = () => async (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/downloadRecentsUnreadV2";
  const params = {
    page: 0,
    pageSize: 15,
    filter: "private",
  };

  const res = await parseAction(method, url, params);

  return res.result.recents;
};

export const fetchUnreadBadges = () => async (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/unreadMessageBadge";
  const res = await parseAction(method, url, {});
  if (res.result) {
    dispatch({
      type: SET_MESSAGES_BADGES,
      messageBadges: res.result,
    });
  }
};

export const fetchThreadsForMe = (page = 0) => (dispatch) => {
  dispatch({
    type: "SET_LOADING",
    value: true,
  });

  const method = "post";
  const url = config.BASE_URL + "/parse/functions/downloadRecentsForMeV2";

  const options = {
    page: page,
    pageSize: 15,
  };

  return parseAction(method, url, options)
    .then((response) => {
      const { recents, hasMore } = response.result;

      dispatch({
        type: "SAVE_THREADS_UNREAD",
        fetchSuccess: true,
        threads: recents,
        hasMore,
        page,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });
    })
    .catch((error) => {
      // TODO handle error when fetching thread
      console.error(error);

      dispatch({
        type: "SAVE_THREADS_UNREAD",
        fetchSuccess: false,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });
    });
};

/**
 * Fetch the message to get the attachment
 * @param {String} ghostId
 * @param {Object[]} threads
 */
export const fetchMessage = (messageObjectId, threadId) => (dispatch) => {
  parseAction("get", config.BASE_URL + "/messages", {
    where: {
      objectId: messageObjectId,
    },
    include: ["actionItem", "attachments", "user"],
  })
    .then((message) => {
      dispatch({
        type: UPDATE_A_MESSAGE,
        message: message.results[0],
        threadId: threadId,
      });
    })
    .catch((error) => {
      // TODO handle error when fetching thread
      console.error(error);
    });
};

const getPatientThread = (threadId, dispatch) => {
  parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
    where: {
      objectId: threadId,
    },
    include: [
      "facility",
      "serviceLocation",
      "primaryInsurance",
      "primaryInsurance.provider",
      "secondaryInsurance",
      "secondaryInsurance.provider",
      "otherInsurance",
      "otherInsurance.provider",
    ],
  })
    .then((result) => {
      if (result.results.length > 0) {
        var group = result.results[0];
        dispatch({
          type: NEW_ACTIVE_THREAD,
          thread: {
            ...group,
            threadId: group.objectId,
            groupName: group.name,
            threadType: group.groupType === "group" || group.groupType === "patient" ? "group" : "private",
          },
        });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

/**
 * Ghost since we don't know if id is group or private
 * @param {String} ghostId
 * @param {Object[]} threads
 */
const ghostThread = (ghostId, threads, dispatch) => {
  let idArray = [Parse.User.current().id, ghostId];
  idArray = sort(idArray).asc((p) => p.toLowerCase());
  let tempThreadId = idArray.join("_");

  let thread = threads.find((t) => {
    return t.threadId === ghostId || t.threadId === tempThreadId;
  });

  if (thread) {
    dispatch({
      type: NEW_ACTIVE_THREAD,
      thread: thread,
    });
  } else {
    parseAction("get", config.BASE_URL + "/parse/classes/Recent", {
      where: {
        threadId: {
          $in: [ghostId, tempThreadId],
        },
        user1: {
          __type: "Pointer",
          className: "_User",
          objectId: Parse.User.current().id,
        },
      },
      include: ["latestSessionMessage", "labels"],
    })
      .then((_threads) => {
        if (_threads.results.length !== 0) {
          // HAS RECENT SET AS NEW THREAD
          dispatch({
            type: NEW_ACTIVE_THREAD,
            thread: _threads.results[0],
          });
        }

        if (_threads.results.length === 0) {
          // HAS RECENT SET AS NEW THREAD
          getPatientThread(ghostId, dispatch);
        }
      })
      .catch((error) => {
        // TODO handle error when fetching thread
        console.error(error);
      });
  }
};

export const setGhostThread = (objectId, threads) => (dispatch) => {
  ghostThread(objectId, threads, dispatch);
};

export const getThreads = () => (dispatch) => {
  dispatch({
    type: GET_THREADS,
  });
};

export const setActiveThread = (thread) => (dispatch) => {
  dispatch({
    type: NEW_ACTIVE_THREAD,
    thread: thread,
  });
};

export const removeThread = (threadId) => (dispatch) => {
  dispatch({
    type: REMOVE_THREAD,
    threadId: threadId,
  });
};

export const createThread = (data, tab) => (dispatch) => {
  if (tab === "provider") return;
  dispatch({
    type: CREATE_THREAD,
    data: data,
    fromTab: tab,
  });
};

export const fetchSelectedThread = (objectId) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_SELECTED_THREAD,
    isFetchingSelectedThread: true,
  });
  parseAction("get", config.BASE_URL + "/parse/classes/Circle/" + objectId)
    .then((response) => {
      dispatch({
        type: SELECTED_THREAD,
        selectedThread: response,
      });

      dispatch({
        type: IS_FETCHING_SELECTED_THREAD,
        isFetchingSelectedThread: false,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: IS_FETCHING_SELECTED_THREAD,
        isFetchingSelectedThread: false,
      });
    });
};

export const forwardMessage = (message, thread, messageTemp) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const Message = Parse.Object.extend("Message");
    const newMessage = new Message();

    switch (thread.threadType) {
      case "private":
        newMessage.set("contact", message.contact);
        break;
      case "group":
        newMessage.set("circle", message.circle);
        break;
    }

    if (message.attachments) {
      newMessage.set("attachments", message.attachments);
    }

    if (message.picture) {
      newMessage.set("picture", message.picture);
    }

    if (message.thumbnail) {
      newMessage.set("thumbnail", message.thumbnail);
    }

    if (message.smsFrom) {
      newMessage.set("smsFrom", message.smsFrom);
      newMessage.set("mmsUrl", message.mmsUrl);
      newMessage.set("mmsContentType", message.mmsContentType);
    }

    const uuid = UUID();
    newMessage.set("text", message.text);
    newMessage.set("messageUUID", uuid);
    newMessage.set("user", message.user);
    newMessage.set("originalMessageDate", message.originalMessageDate);
    newMessage.set("isNew", true);

    newMessage.save().then(
      (savedMessage) => {
        let jsonObject = JSON.stringify(savedMessage.toJSON());
        jsonObject = JSON.parse(jsonObject);

        dispatch({
          type: SEND_NEW_MESSAGE,
          message: message,
          threadId: thread.threadId,
          isForwarding: true,
        });

        dispatch({
          type: SEND_MESSAGE_STATUS,
          message: message,
          newMessageObjectId: savedMessage.id,
          threadId: thread.threadId,
          status: "success",
        });
        resolve(jsonObject);
      },
      (error) => {
        dispatch({
          type: SEND_MESSAGE_STATUS,
          message: message,
          threadId: thread.threadId,
          status: "error",
        });

        reject(new Error("Failed to send message"));
      }
    );
  });
};

export const forwardToGroup = ({ message, thread, text }) => async (dispatch) => {
  const date = new Date();

  const params = {
    messageId: message.objectId,
    threadId: thread.threadId,
    originalMessageDate: date.toISOString(),
    text,
  };

  try {
    const url = config.BASE_URL + "/parse/functions/forwardMessageToGroup";

    const res = await parseAction("post", url, params);

    const savedMessage = res.result.message;

    dispatch({
      type: SEND_NEW_MESSAGE,
      message: {
        ...message,
        createdAt: date,
      },
      threadId: thread.threadId,
      isForwarding: true,
    });

    dispatch({
      type: SEND_MESSAGE_STATUS,
      message: {
        ...message,
        createdAt: date,
      },
      newMessageObjectId: savedMessage.objectId,
      threadId: thread.threadId,
      status: "success",
    });
  } catch (err) {
    dispatch({
      type: SEND_MESSAGE_STATUS,
      message: message,
      threadId: thread.threadId,
      status: "error",
    });

    return Promise.reject(err);
  }
};

export const forwardToContact = ({ message, thread, text }) => async (dispatch) => {
  const date = new Date();

  const params = {
    messageId: message.objectId,
    contactId: thread.partnerObjectId,
    originalMessageDate: date.toISOString(),
    text,
  };

  try {
    const url = config.BASE_URL + "/parse/functions/forwardMessageToContact";

    const res = await parseAction("post", url, params);

    const savedMessage = res.result.message;

    dispatch({
      type: SEND_NEW_MESSAGE,
      message: {
        ...message,
        createdAt: date,
      },
      threadId: thread.threadId,
      isForwarding: true,
    });

    dispatch({
      type: SEND_MESSAGE_STATUS,
      message: {
        ...message,
        createdAt: date,
      },
      newMessageObjectId: savedMessage.objectId,
      threadId: thread.threadId,
      status: "success",
    });
  } catch (err) {
    dispatch({
      type: SEND_MESSAGE_STATUS,
      message: message,
      threadId: thread.threadId,
      status: "error",
    });

    return Promise.reject(err);
  }
};

export const sendGroupMessage = ({
  text = "",
  threadId = "",

  attachmentIds = [],
  attention = [],
  urgent,
  replyTo,
  actionItem,
  priorityLevel = 0,
}) => async (dispatch) => {
  const date = new Date();
  const params = {
    originalMessageDate: date.toISOString(),
    attention: attention,
    threadId,
    isUrgent: urgent,
    sendSmsOnAttention: attention.length > 0 || urgent ? true : false,
    text,
    actionItem,
    priorityLevel,
  };

  try {
    const url = config.BASE_URL + "/parse/functions/sendGroupMessage:v3";

    const res = await parseAction("post", url, params, { "Content-Type": "application/json" });

    const savedMessage = res.result.message;
    // console.log("savedMess:", savedMessage);

    dispatch({
      type: SEND_NEW_MESSAGE,
      message: {
        ...savedMessage,
        createdAt: date,
      },
      threadId,
      isForwarding: true,
    });

    dispatch({
      type: SEND_MESSAGE_STATUS,
      message: {
        ...savedMessage,
        createdAt: date,
      },
      newMessageObjectId: savedMessage.objectId,
      threadId,
      status: "success",
    });

    return savedMessage;
  } catch (err) {
    dispatch({
      type: SEND_MESSAGE_STATUS,
      message: {
        text,
      },
      threadId: threadId,
      status: "error",
    });

    return Promise.reject(err);
  }
};

export const sendPrivateMessage = ({ text = "", partnerObjectId = "", threadId = "", attachmentIds = [] }) => async (dispatch) => {
  const date = new Date();

  const params = {
    originalMessageDate: date.toISOString(),
    contactId: partnerObjectId,
    text,
  };

  if (attachmentIds.length > 0) {
    params.attachmentIds = attachmentIds;
  }

  try {
    const url = config.BASE_URL + "/parse/functions/sendPrivateMessage:v2";

    const res = await parseAction("post", url, params, 
      { "Content-Type": "application/json" }
    );

    const savedMessage = res.result.message;

    dispatch({
      type: SEND_NEW_MESSAGE,
      message: {
        ...savedMessage,
        createdAt: date,
      },
      threadId: threadId,
      isForwarding: true,
    });

    dispatch({
      type: SEND_MESSAGE_STATUS,
      message: {
        ...savedMessage,
        createdAt: date,
      },
      newMessageObjectId: savedMessage.objectId,
      threadId: threadId,
      status: "success",
    });
  } catch (err) {
    dispatch({
      type: SEND_MESSAGE_STATUS,
      message: {
        text,
      },
      threadId: threadId,
      status: "error",
    });

    return Promise.reject(err);
  }
};

export const forwardConference = (message, thread, messageTemp) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const Message = Parse.Object.extend("Message");
    const newMessage = new Message();

    switch (thread.threadType) {
      case "private":
        newMessage.set("contact", message.contact);
        break;
      case "group":
        newMessage.set("circle", message.circle);
        break;
    }

    if (message.attachments) {
      newMessage.set("attachments", message.attachments);
    }

    if (message.picture) {
      newMessage.set("picture", message.picture);
    }

    const uuid = UUID();
    newMessage.set("text", message.text);
    newMessage.set("messageUUID", uuid);
    newMessage.set("user", message.user);
    newMessage.set("originalMessageDate", message.originalMessageDate);
    newMessage.set("isNew", true);

    newMessage.save().then(
      (savedMessage) => {
        let jsonObject = JSON.stringify(savedMessage.toJSON());
        jsonObject = JSON.parse(jsonObject);

        dispatch({
          type: SEND_MESSAGE_STATUS,
          message: message,
          newMessageObjectId: savedMessage.id,
          threadId: thread.threadId,
          status: "success",
        });
        resolve(jsonObject);
      },
      (error) => {
        dispatch({
          type: SEND_MESSAGE_STATUS,
          message: message,
          threadId: thread.threadId,
          status: "error",
        });

        reject(new Error("Failed to send message"));
      }
    );
  });
};

export const sendNewMessage = (message, thread, resend = false, alsoPrivate = false) => (dispatch) => {
  dispatch({
    type: IS_SENDING_MESSAGE,
    isSending: true,
  });

  if (resend) {
    dispatch({
      type: SEND_MESSAGE_STATUS,
      messageObjectId: message.objectId,
      message: message,
      threadId: thread.threadId,
      status: "sending",
    });
  } else {
    if (message.file) {
      if (message.fileInfo.fileType === "audio/mp3") {
        // dont do anything
      } else if (message.fileInfo.filetype === "application/pdf") {
        message.picture = {
          name: message.fileInfo.filename,
          url: message.fileInfo.preview,
        };
        message.thumbnail = {
          name: message.fileInfo.filename,
          url: message.fileInfo.preview,
        };
      } else {
        message.picture = {
          name: message.fileInfo.filename,
          url: message.fileInfo.preview,
        };
      }
    }
    if (!alsoPrivate) {
      dispatch({
        type: SEND_NEW_MESSAGE,
        message: message,
        threadId: thread.threadId,
      });
    }
  }

  dispatch({
    type: IS_SENDING_MESSAGE,
    isSending: false,
  });

  return sendMessage(message, thread, dispatch)
    .then((messageObject) => {
      const jsonObject = messageObject;

      if (message.file) {
        jsonObject.fileInfo = message.fileInfo;
        jsonObject.file = message.file;
      }

      jsonObject.user = Parse.User.current().toJSON();

      dispatch({
        type: SEND_MESSAGE_STATUS,
        messageObjectId: message.objectId,
        message: jsonObject,
        newMessageObjectId: messageObject.objectId,
        threadId: thread.threadId,
        status: "success",
      });

      dispatch({
        type: IS_SENDING_MESSAGE,
        isSending: false,
      });

      return jsonObject;
    })
    .catch((error) => {
      dispatch({
        type: SEND_MESSAGE_STATUS,
        message: message,
        threadId: thread.threadId,
        status: "error",
      });

      dispatch({
        type: IS_SENDING_MESSAGE,
        isSending: false,
      });

      return Promise.reject(error);
    });
};

// FIXME bug when fetching empty results
export const fetchPrivateMessages = (partnerObjectId, date, threadId, fetchingMore, callback) => (dispatch) => {
  if (fetchingMore) {
  } else {
    dispatch({
      type: FETCH_CONVERSATION_STATUS,
      threadId: threadId,
    });
  }

  const nd = new Date(date); // Convert ISOString to Date object
  nd.setMilliseconds(nd.getMilliseconds() - 5);

  const currentUser = Parse.User.current();
  parseAction("post", config.BASE_URL + "/messages", {
    lastMessageDate: new Date(nd).toISOString(),
    threadId,
    limit: 15,
  })
    .then((conversation) => {
      let messages = [];
      let files = [];
      for (let x = 0; x < conversation.result.messages.length; x++) {
        if (x > 0) {
          let lastDate = moment(conversation.result.messages[x - 1].createdAt);
          let thisDate = moment(conversation.result.messages[x].createdAt);
          var duration = moment.duration(lastDate.diff(thisDate));
          var minutes = duration.asMinutes();
          let sameDay = moment(lastDate).isSame(thisDate, "day");
          if (!sameDay) {
            let newData = {
              __TimeType: "TimeDifferent",
              dateTime: conversation.result.messages[x - 1].createdAt,
            };
            messages = [newData, ...messages];
          }
        }
        const currentConversation = conversation.result.messages[x];

        const currentPic = currentConversation.picture;

        const attachmentPics = getAttachmentPics(currentConversation);
        files = files.concat(attachmentPics);

        if (currentPic) {
          currentPic.conversationId = currentConversation.objectId;

          files = [...files, currentPic];
        }

        messages = [conversation.result.messages[x], ...messages];
      }
      // Add TimeDiff in the start of conversation
      if (messages.length !== 0) {
        let newData = {
          __TimeType: "TimeDifferent",
          dateTime: messages[0].createdAt,
        };
        messages = [newData, ...messages];
      }

      dispatch({
        type: FETCH_CONVERSATION_MESSAGES,
        conversation: messages,
        files: files,
        threadId: threadId,
      });

      if (!fetchingMore) {
        if (messages.length !== 0) {
          let messageToBeSeen = messages[messages.length - 1];
          dispatch(
            seenMessage(
              threadId,
              messageToBeSeen.objectId,
              (messageToBeSeen.originalMessageDate || {}).iso,
              "NotSessionMessage"
            )
          );
        }
      }

      if (callback) {
        callback(conversation.result.hasMore);
      }
    })
    .catch((error) => {
      // TODO handle error when fetching recents
      console.error(error);
    });
};

export const fetchGroupMessages = (
  groupObjectId, 
  date, 
  threadId, 
  fetchingMore, 
  reload = false, 
  callback, 
  queryUp = ""
) => (
  dispatch
) => {
  if (fetchingMore) {
  } else {
    dispatch({
      type: FETCH_CONVERSATION_STATUS,
      threadId: threadId,
    });
  }

  const nd = new Date(date); // Convert ISOString to Date object
  nd.setMilliseconds(nd.getMilliseconds() - 5);

  const currentUser = Parse.User.current();
  parseAction("post", config.BASE_URL + "/messages", {
    lastMessageDate: new Date(nd).toISOString(),
    threadId,
    pageSize: 15,
    queryUp: queryUp
  })
    .then((conversation) => {
      let messages = [];
      let files = [];
      for (let x = 0; x < conversation.result.messages.length; x++) {
        if (x > 0) {
          let lastDate = moment(conversation.result.messages[x - 1].createdAt);
          let thisDate = moment(conversation.result.messages[x].createdAt);
          var duration = moment.duration(lastDate.diff(thisDate));
          var minutes = duration.asMinutes();
          let sameDay = moment(lastDate).isSame(thisDate, "day");
          if (!sameDay) {
            let newData = {
              __TimeType: "TimeDifferent",
              dateTime: conversation.result.messages[x - 1].createdAt,
            };
            messages = [newData, ...messages];
          }
        }

        const currentConversation = conversation.result.messages[x];

        const currentPic = currentConversation.picture;

        const attachmentPics = getAttachmentPics(currentConversation);
        files = files.concat(attachmentPics);

        if (currentPic) {
          currentPic.conversationId = currentConversation.objectId;

          files = [...files, currentPic];
        }
        messages = [conversation.result.messages[x], ...messages];
      }

      // Add TimeDiff in the start of conversation
      if (messages.length !== 0) {
        let newData = {
          __TimeType: "TimeDifferent",
          dateTime: messages[0].createdAt,
        };
        messages = [newData, ...messages];
      }
      dispatch({
        type: FETCH_CONVERSATION_MESSAGES,
        conversation: messages,
        files: files,
        threadId: threadId,
        reload,
      });

      if (!fetchingMore) {
        if (messages.length !== 0) {
          let messageToBeSeen = messages[messages.length - 1];
          dispatch(
            seenMessage(
              threadId,
              messageToBeSeen.objectId,
              (messageToBeSeen.originalMessageDate || {}).iso,
              "NotSessionMessage"
            )
          );
        }
      }
      if (callback) {
        callback(conversation.result.hasMore);
      }
    })
    .catch((error) => {
      // TODO handle error when fetching recents
      console.error(error);
    });
};

function getAttachmentPics(message) {
  const { attachments = [] } = message;

  return attachments
    .filter((a) => {
      return (a.fileType || "").includes("image") || (a.fileType || "").includes("pdf");
    })
    .map((a) => {
      return {
        ...a.file,
        conversationId: message.objectId,
        thumbUrl: a.thumbNail ? a.thumbNail.url : "",
      };
    });
}

export const fetchAdhocMessages = (threadId, date, fetchingMore) => (dispatch) => {
  if (fetchingMore) {
  } else {
    dispatch({
      type: FETCH_CONVERSATION_STATUS,
      threadId: threadId,
    });
  }

  const currentUser = Parse.User.current();
  parseAction("get", config.BASE_URL + "/parse/classes/Message", {
    where: {
      threadId: threadId,
      archivedBy: {
        $nin: [
          {
            __type: "Pointer",
            className: "_User",
            objectId: currentUser.id,
          },
        ],
      },
      createdAt: {
        $lt: {
          __type: "Date",
          iso: date,
        },
      },
    },
    include: ["actionItem", "user", "attachments", "conference", "conference.host"],
    order: "-createdAt",
    limit: 15,
  })
    .then((conversation) => {
      let messages = [];
      let files = [];
      for (let x = 0; x < conversation.results.length; x++) {
        if (x > 0) {
          let lastDate = moment(conversation.results[x - 1].createdAt);
          let thisDate = moment(conversation.results[x].createdAt);
          var duration = moment.duration(lastDate.diff(thisDate));
          var minutes = duration.asMinutes();
          let sameDay = moment(lastDate).isSame(thisDate, "day");
          if (!sameDay) {
            let newData = {
              __TimeType: "TimeDifferent",
              dateTime: conversation.results[x - 1].createdAt,
            };
            messages = [newData, ...messages];
          }
        }
        const currentConversation = conversation.results[x];

        const currentPic = currentConversation.picture;

        if (currentPic) {
          currentPic.conversationId = currentConversation.objectId;

          files = [...files, currentPic];
        }
        messages = [conversation.results[x], ...messages];
      }

      // Add TimeDiff in the start of conversation
      if (messages.length !== 0) {
        let newData = {
          __TimeType: "TimeDifferent",
          dateTime: messages[0].createdAt,
        };
        messages = [newData, ...messages];
      }
      dispatch({
        type: FETCH_CONVERSATION_MESSAGES,
        conversation: messages,
        files: files,
        threadId: threadId,
      });

      if (!fetchingMore) {
        if (messages.length !== 0) {
          let messageToBeSeen = messages[messages.length - 1];
          dispatch(
            seenMessage(
              threadId,
              messageToBeSeen.objectId,
              (messageToBeSeen.originalMessageDate || {}).iso,
              "NotSessionMessage"
            )
          );
        }
      }
    })
    .catch((error) => {
      // TODO handle error when fetching recents
      console.error(error);
    });
};

export const fetchSubgroupMessages = (threadId, date, fetchingMore = false) => (dispatch) => {
  if (!fetchingMore) {
    dispatch({
      type: FETCH_CONVERSATION_STATUS,
      threadId: threadId,
    });
  }

  const currentUser = Parse.User.current();
  parseAction("get", config.BASE_URL + "/parse/classes/SessionMessage", {
    where: {
      subgroupSession: {
        __type: "Pointer",
        className: "SubgroupSession",
        objectId: threadId,
      },
      createdAt: {
        $lt: {
          __type: "Date",
          iso: date,
        },
      },
    },
    include: ["createdBy", "attachments"],
    order: "-createdAt",
    limit: 15,
  })
    .then((conversation) => {
      let messages = [];
      let files = [];
      for (let x = 0; x < conversation.results.length; x++) {
        if (x > 0) {
          let lastDate = moment(conversation.results[x - 1].createdAt);
          let thisDate = moment(conversation.results[x].createdAt);
          var duration = moment.duration(lastDate.diff(thisDate));
          var minutes = duration.asMinutes();
          let sameDay = moment(lastDate).isSame(thisDate, "day");
          if (!sameDay) {
            let newData = {
              __TimeType: "TimeDifferent",
              dateTime: conversation.results[x - 1].createdAt,
            };
            messages = [newData, ...messages];
          }
        }
        const currentConversation = conversation.results[x];

        const currentPic = currentConversation.picture;

        if (currentPic) {
          currentPic.conversationId = currentConversation.objectId;

          files = [...files, currentPic];
        }

        conversation.results[x].user = conversation.results[x].createdBy;
        messages = [conversation.results[x], ...messages];
      }

      // Add TimeDiff in the start of conversation
      if (messages.length !== 0) {
        let newData = {
          __TimeType: "TimeDifferent",
          dateTime: messages[0].createdAt,
        };
        messages = [newData, ...messages];
      }
      dispatch({
        type: FETCH_CONVERSATION_MESSAGES,
        conversation: messages,
        files: files,
        threadId: threadId,
      });

      if (!fetchingMore) {
        if (messages.length !== 0) {
          let messageToBeSeen = messages[messages.length - 1];
          dispatch(
            seenMessage(
              threadId,
              messageToBeSeen.subgroupSession.objectId,
              (messageToBeSeen.originalMessageDate || {}).iso,
              "sessionMessage"
            )
          );
        }
      }
    })
    .catch((error) => {
      // TODO handle error when fetching recents
      console.error(error);
    });
};

export const checkUncheckMessages = (messagesArray = [], message = {}, isChecked = false) => (dispatch) => {
  const messages = messagesArray.map((item) => ({
    ...item,
    isChecked: item.objectId === message.objectId ? isChecked : item.isChecked || false,
  }));
  const selected_messages = messages.filter((item) => item.isChecked);

  dispatch({
    type: SELECT_UNSELECT_MESSAGES,
    messages,
  });

  dispatch({
    type: MESSAGES_TO_FORWARD,
    selected_messages,
  });
};

export const resetSelectedMessages = (messagesArray = []) => (dispatch) => {
  const messages = messagesArray.map((item) => ({
    ...item,
    isChecked: false,
  }));

  dispatch({
    type: SELECT_UNSELECT_MESSAGES,
    messages,
  });

  dispatch({
    type: MESSAGES_TO_FORWARD,
    selected_messages: [],
  });
};

export const openCloseCheckBoxes = (isOpen) => (dispatch) => {
  dispatch({
    type: OPEN_CLOSE_CHECKBOXES,
    showCheckBoxes: isOpen,
  });
};

/**
 * Filter thread base on user search
 * @param {String} text
 */
export const filterMessages = (text) => (dispatch) => {
  dispatch({
    type: FILTER_MESSAGES,
    text: text,
  });
};

/**
 * Receive New Message from pubnub
 * @param {Object} message
 * @param {string} threadId
 */
export const receiveNewMessage = (message, threadId) => (dispatch) => {
  dispatch({
    type: RECEIVE_MESSAGE,
    message: message,
    threadId: threadId,
  });
};

export const recentUpdated = (recent) => (dispatch) => {
  dispatch({
    type: RECENT_UPDATED,
    thread: recent,
  });
};

export const archivedMessage = (message) => (dispatch) => {
  let archivedBy = {
    __type: "Pointer",
    className: "_User",
    objectId: Parse.User.current().id,
  };

  // Create the object.
  var Message = Parse.Object.extend("Message");
  var messageObject = new Message();
  messageObject.id = message.objectId;

  messageObject.addUnique("archivedBy", archivedBy);

  messageObject.save().then(
    (res) => {
      dispatch({
        type: ARCHIVE_MESSAGE,
        message: message,
      });
    },
    (error) => {
      // FIXME notif for unsuccessfull archive(delete)
    }
  );
};

export const deleteMessage = (message) => (dispatch) => {
  let messageTobeDeleted = [];
  messageTobeDeleted = [...messageTobeDeleted, message.objectId];

  // parseAction("post",config.BASE_URL + '/parse/functions/deleteMessages',
  //     {
  //         messageObjectIds : messageTobeDeleted
  //     }
  // )
  // .then(req => {
  //     dispatch({
  //         type : ARCHIVE_MESSAGE,
  //         message : message
  //     })
  // })
  // .catch(error => {
  //     // TODO handle error when fetching thread
  //     console.log(error);
  // });

  const params = { messageObjectIds: messageTobeDeleted };
  Parse.Cloud.run("deleteMessages", params)
    .then((req) => {
      dispatch({
        type: ARCHIVE_MESSAGE,
        message: message,
      });
    })
    .catch((error) => {});
};

export const updateNotifSettings = (recentId, notificationType) => (dispatch) => {
  const data = {
    recentId,
    notificationType,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction("post", config.BASE_URL + "/parse/functions/updateNotifSettings", data, headers).then((req) => {
    dispatch({
      type: "NOTIF_TYPE_UPDATED",
      recentId,
      notificationType,
    });
  });
};

/**
 * Send new message to parse
 * @private
 */
const sendMessage = async (message, thread, dispatch) => {
  const { text, file, attention, replyTo, fileInfo = {}, urgent = false, actionItem, priorityLevel = 0 } = message;

  const attachment = file ? await messageApi.createAttachment(file) : null;

  const payload = {
    recent: thread,
    attention,
    text,
    urgent,
    replyTo,
    actionItem,
    priorityLevel,
  };

  if (attachment) {
    payload.attachmentIds = [attachment.objectId];
  }

  const sentMessage = await messageApi.sendMessage(payload);

  return sentMessage;
};

const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    let fileType = file.type;
    let fileName = file.name || "";

    const splitted = fileName.split(".");

    const formatted = splitted.map((string = "") => {
      return string.replace(/[^\w\s]/gi, "").replace(/[\s,.]/g, "_");
    });

    const joined = formatted.join(".");

    let parseFile;
    if (file.type === "base64/image") {
      parseFile = new Parse.File(joined, { base64: file.uri });
    } else {
      parseFile = new Parse.File(joined, file, fileType);
    }
    console.log(parseFile);
    parseFile.save().then(
      function() {
        resolve(parseFile);
      },
      function(error) {
        console.log(error);
        reject(new Error(error));
      }
    );
  });
};

const uploadThumb = async (base64) => {
  const filename = util.uuid() + "_thumb.png";

  const parseFile = new Parse.File(filename, { base64 });

  const uploaded = await parseFile.save();

  return uploaded;
};

/**
 * Fetch unread messages count
 */
export const fetchUnreadCount = () => (dispatch) => {
  parseAction("post", config.BASE_URL + "/parse/functions/getUnreadCount")
    .then((response) => {
      dispatch({
        type: "SET_UNREAD_COUNT",
        value: response.result.unreadMessagesCount,
      });
    })
    .catch((error) => {
      // TODO handle error when fetching thread
      console.error(error);
    });
};

export const setEmojiShown = (emojiShown) => (dispatch) => {
  dispatch({
    type: "SET_EMOJI_SHOWN",
    value: emojiShown,
  });
};

export const showTagsModal = (recent) => (dispatch) => {
  dispatch({
    type: "SHOW_TAGS_MODAL",
    recent,
  });
};

export const hideTagsModal = () => (dispatch) => {
  dispatch({
    type: "HIDE_TAGS_MODAL",
  });
};

export const tagCircle = (circleId, circleLabel) => (dispatch) => {
  const data = {
    circleId,
    circleLabelId: circleLabel.objectId,
  };

  dispatch({
    type: "TAG_CIRCLE",
    circleId,
    label: circleLabel,
  });

  return parseAction("post", config.BASE_URL + "/parse/functions/tagCircle", data);
};

export const untagCircle = (circleId, circleLabel) => (dispatch) => {
  const data = {
    circleId,
    circleLabelId: circleLabel.objectId,
  };

  dispatch({
    type: "UNTAG_CIRCLE",
    circleId,
    label: circleLabel,
  });

  return parseAction("post", config.BASE_URL + "/parse/functions/untagCircle", data);
};

export const tagRecent = (recentId, circleLabel) => (dispatch) => {
  const data = {
    recentId,
    circleLabelId: circleLabel.objectId,
  };

  dispatch({
    type: "TAG_RECENT",
    recentId,
    label: circleLabel,
  });

  return parseAction("post", config.BASE_URL + "/parse/functions/tagRecent", data);
};

export const untagRecent = (recentId, circleLabel) => (dispatch) => {
  const data = {
    recentId,
    circleLabelId: circleLabel.objectId,
  };

  dispatch({
    type: "UNTAG_RECENT",
    recentId,
    label: circleLabel,
  });

  return parseAction("post", config.BASE_URL + "/parse/functions/untagRecent", data);
};

export const showTagsFilterModal = () => (dispatch) => {
  dispatch({
    type: "SHOW_TAGS_FILTER_MODAL",
  });
};

export const hideTagsFilterModal = () => (dispatch) => {
  dispatch({
    type: "HIDE_TAGS_FILTER_MODAL",
  });
};

export const filterByTag = (circleLabelId) => (dispatch) => {
  dispatch(showTagsSelect(circleLabelId));

  dispatch(fetchThreadsByLabel({ circleLabelId }));
};

export const unfilterByTag = () => (dispatch) => {
  dispatch(hideTagsSelect());
};

export const showTagsSelect = (circleLabelId) => (dispatch) => {
  dispatch({
    type: "SHOW_TAGS_SELECT",
    circleLabelId,
  });
};

export const hideTagsSelect = () => (dispatch) => {
  dispatch({
    type: "HIDE_TAGS_SELECT",
  });
};

export const fetchThreadsByLabel = ({ page = 0, circleLabelId }) => (dispatch) => {
  dispatch({
    type: "SET_LOADING",
    value: true,
  });

  const method = "post";
  const url = config.BASE_URL + "/parse/functions/downloadRecents";

  const options = {
    page: page,
    pageSize: 15,
    circleLabelId,
  };

  return parseAction(method, url, options)
    .then((response) => {
      const { recents, hasMore } = response.result;

      dispatch({
        type: "SAVE_THREADS_BY_LABEL",
        fetchSuccess: true,
        threads: recents,
        hasMore,
        page,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });
    })
    .catch((error) => {
      // TODO handle error when fetching thread
      console.error(error);

      dispatch({
        type: "SAVE_THREADS_BY_LABEL",
        fetchSuccess: false,
      });

      dispatch({
        type: "SET_LOADING",
        value: false,
      });
    });
};

export const linkToLabel = (circleLabelId) => (dispatch) => {
  dispatch({
    type: "LINK_TO_LABEL",
  });

  dispatch(filterByTag(circleLabelId));
};

export const linkToImportant = () => (dispatch) => {
  // dispatch(fetchThreadsUnread());

  dispatch({
    type: "LINK_TO_IMPORTANT",
  });
};

export const linkToUrgent = () => (dispatch) => {
  dispatch({
    type: "LINK_TO_URGENT",
  });
};

export const linkToRecent = (recent) => (dispatch) => {
  dispatch({
    type: "LINK_TO_RECENT",
  });

  dispatch(recent.type === "message" ? setActiveThread(recent) : createThread(recent, recent.type));
};

export const linkToInbox = () => (dispatch) => {
  dispatch({
    type: "LINK_TO_INBOX",
  });
};

export const linkToTask = (sub) => (dispatch) => {
  dispatch({
    type: "LINK_TO_TASKS",
    subFilter: sub,
  });
};

export const linkToNotes = () => (dispatch) => {
  dispatch({
    type: "LINK_TO_NOTES",
  });

  dispatch(setConferenceTab("LOGS"));

  dispatch(getConferenceCalls());
};

export const linkToTodos = () => (dispatch) => {
  dispatch({
    type: "LINK_TO_TODOS",
  });
};

export const setLabelNotes = (circleId, labelNotes) => (dispatch) => {
  const url = config.BASE_URL + "/parse/classes/Circle/" + circleId;

  const data = {
    labelNotes,
  };

  return parseAction("put", url, data);
};

export const createActionItems = (circleId, { text, assignedTo, thread, threadName, threadId, due_date }) => async (
  dispatch
) => {
  dispatch({
    type: IS_SENDING_MESSAGE,
    isSending: true,
  });

  /** Prevent duplicate item due to API update */
  // const Circle = Parse.Object.extend("Circle");
  // const circle = new Circle({ objectId: circleId });

  // const ActionItem = Parse.Object.extend("ActionItem");
  // const actionItem = new ActionItem({
  //   assignedTo,
  //   threadName,
  //   thread,
  //   threadId,
  //   text,
  // });

  const item = await parseAction(
    "post",
    config.BASE_URL + "/parse/functions/actionItem",
    {
      assignedTo: typeof assignedTo === "string" ? assignedTo : assignedTo.objectId,
      threadName,
      thread,
      threadId,
      text,
      due_date,
    },
    { "Content-Type": "application/json" }
  );
  return item.result.message;

  /** Prevent duplicate item due to API update */

  // Save the new action item
  // const newActionItem = await actionItem.save();
  // Store it to the circle's
  // circle.addUnique("actionItems", actionItem);
  // await circle.save();

  // return {
  //   ...actionItem.toJSON(),
  //   assignedTo,
  // };
};

export const completeActionItem = (objectId) => (dispatch) => {
  const ActionItem = Parse.Object.extend("ActionItem");

  const actionItem = new ActionItem({
    objectId,
    status: "DONE",
  });

  return actionItem.save();
};

export const updateActionItem = (item) => async () => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/updateActionItem";
  const params = {
    actionItemId: item.objectId,
    assignedTo: item.assignedTo.objectId,
    text: item.text,
    due_date: item.due_date || "",
  };

  await parseAction(method, url, params, { "Content-Type": "application/json" })
    .then((response) => {
      return response.result.actionItem;
    })
    .catch((error) => {
      console.error(error);
    });

  // return actionItem.result.actionItem;
};

export const getActionItemDiscussion = (item) => async () => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/getActionItemDiscussion";
  const params = {
    actionItemId: item,
  };

  return await parseAction(method, url, params, { "Content-Type": "application/json" })
    .then((response) => {
      return response.result.actionItem;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getCirclesWithTodos = () => async (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/getCirclesWithTodos";
  const params = {};

  const res = await parseAction(method, url, params);

  return res.result.recents;
};

export const getAllToDos = (
  page = 0,
  currentToDos = [],
  pageSize = 10,
  assignedToMe = 1,
  status = "ALL",
  priorityLevel = 2
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const params = {
      pageSize,
      page,
      assignedToMe,
      status,
      priorityLevel,
    };

    dispatch({
      type: TO_DOS_LOADING,
      isToDosLoading: true,
    });

    parseAction("post", config.BASE_URL + "/parse/functions/getAllTodos", params)
      .then(
        (response) => {
          const { todos = [] } = response.result;

          switch (status) {
            case "PENDING":
              switch (priorityLevel) {
                case 2:
                  dispatch({
                    type: SET_TO_DOS_PENDING_HIGH,
                    data: [...currentToDos, ...todos] || [],
                    page: response.result.page,
                    hasMore: response.result.hasMore,
                  });
                  break;
                case 1:
                  dispatch({
                    type: SET_TO_DOS_PENDING_MED,
                    data: [...currentToDos, ...todos] || [],
                    page: response.result.page,
                    hasMore: response.result.hasMore,
                  });
                  break;
                case 0:
                  dispatch({
                    type: SET_TO_DOS_PENDING_ROUTINE,
                    data: [...currentToDos, ...todos] || [],
                    page: response.result.page,
                    hasMore: response.result.hasMore,
                  });
                  break;
                default:
                  dispatch({
                    type: SET_TO_DOS_PENDING,
                    data: [...currentToDos, ...todos] || [],
                    page: response.result.page,
                    hasMore: response.result.hasMore,
                  });
                  break;
              }
              break;
            case "DONE":
              switch (priorityLevel) {
                case 2:
                  dispatch({
                    type: SET_TO_DOS_DONE_HIGH,
                    data: [...currentToDos, ...todos] || [],
                    page: response.result.page,
                    hasMore: response.result.hasMore,
                  });
                  break;
                case 1:
                  dispatch({
                    type: SET_TO_DOS_DONE_MED,
                    data: [...currentToDos, ...todos] || [],
                    page: response.result.page,
                    hasMore: response.result.hasMore,
                  });
                  break;
                case 0:
                  dispatch({
                    type: SET_TO_DOS_DONE_ROUTINE,
                    data: [...currentToDos, ...todos] || [],
                    page: response.result.page,
                    hasMore: response.result.hasMore,
                  });
                  break;
                default:
                  dispatch({
                    type: SET_TO_DOS_DONE,
                    data: [...currentToDos, ...todos] || [],
                    page: response.result.page,
                    hasMore: response.result.hasMore,
                  });
                  break;
              }
              break;
            case "ALL":
              {
                switch (priorityLevel) {
                  case 2:
                    dispatch({
                      type: SET_TO_DOS_ALL_HIGH,
                      data: [...currentToDos, ...todos] || [],
                      page: response.result.page,
                      hasMore: response.result.hasMore,
                    });
                    break;
                  case 1:
                    dispatch({
                      type: SET_TO_DOS_ALL_MED,
                      data: [...currentToDos, ...todos] || [],
                      page: response.result.page,
                      hasMore: response.result.hasMore,
                    });
                    break;
                  case 0:
                    dispatch({
                      type: SET_TO_DOS_ALL_ROUTINE,
                      data: [...currentToDos, ...todos] || [],
                      page: response.result.page,
                      hasMore: response.result.hasMore,
                    });
                    break;
                  default:
                    dispatch({
                      type: SET_TO_DOS_ALL,
                      data: [...currentToDos, ...todos] || [],
                      page: response.result.page,
                      hasMore: response.result.hasMore,
                    });
                    break;
                }
              }
              break;
          }
          // Always update RECENT reducer
          dispatch({
            type: SET_TO_DOS_RECENT,
            data: [...currentToDos, ...todos] || [],
            page: response.result.page,
            hasMore: response.result.hasMore,
          });

          dispatch({
            type: TO_DOS_LOADING,
            isToDosLoading: false,
          });
          // dispatch({
          //   type: HAS_MORE_TO_DOS,
          //   hasMoreToDos: response.result.hasMore,
          // });
          resolve(todos);
        },
        (error) => {
          reject(error);
        }
      )
      .catch((error) => {
        dispatch({
          type: TO_DOS_LOADING,
          isLoading: false,
        });

        reject(error);
        return new Error(error);
      });
  });
};
export const getAllCritical = () => async (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/getAllTodos";
  const params = {
    page: 0,
    pageSize: 99,
    assignedToMe: 1,
    status: "PENDING",
    priorityLevel: 3,
  };

  const res = await parseAction(method, url, params);
  let critical = res.result.todos;

  dispatch({
    type: SET_TO_DOS_CRITICAL,
    data: [...critical] || [],
  });

  return critical;
};

export const getCritical = (id) => async (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/getCritical";
  const params = { circleId: id };

  const res = await parseAction(method, url, params);
  let critical = res.result.critical;

  return critical;
};

export const showReplyPreview = (replyTo) => (dispatch) => {
  dispatch({
    type: "SHOW_REPLY_PREVIEW",
    replyTo,
  });
};

export const hideReplyPreview = () => (dispatch) => {
  dispatch({
    type: "HIDE_REPLY_PREVIEW",
  });
};

export const setReminderThread = (circleId, reminder, assignedTo, text) => (dispatch) => {
  const params = {
    circleId,
    remind_me_at: reminder.date.toISOString(),
    assignedTo: assignedTo,
    text: text,
  };
  return parseAction("post", config.BASE_URL + "/parse/functions/tagCritical", params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const unsetReminderThread = (circleId) => (dispatch) => {
  return parseAction("post", config.BASE_URL + "/parse/functions/untagCritical", { circleId })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
};

// Tempt save to local storage
export const fetchRecentThreads = () => (dispatch) => {
  const recents = JSON.parse(localStorage.getItem("recentMesages") || "[]");

  dispatch({
    type: GET_THREADS_RECENT,
    threads: recents,
    status: "SUCCESS",
  });
};

export const removeMessage = (message) => (dispatch) => {
  dispatch({
    type: REMOVE_THREAD_UNREAD,
    threads: message,
  });
};

export const setPrevOpenedThread = (thread) => (dispatch) => {
  dispatch({
    type: SET_PREV_OPENED_THREAD,
    prevOpenedThread: thread,
  });
};

export const setAttentionedMessage = (data) => (dispatch) => {
  dispatch({
    type: SET_ATTENTIONED_MESSAGE,
    attentionedMessage: data,
  });
};

export const setMessagesShown = (read) => (dispatch) => {
  dispatch({
    type: SET_MESSAGES_SHOWN,
    read,
  });
};

export const setActivityList = (activity) => (dispatch) => {
  dispatch({
    type: SET_ACTIVITY_LIST,
    activities: [activity],
  });
};

export const getActivity = (current = [], read = "", page = 0) => async (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/getActivity";
  const params = {
    page: page,
    pageSize: 15,
    read: read,
  };

  const res = await parseAction(method, url, params, { "Content-Type": "application/json" })
    .then((response) => {
      const { activity } = response.result;

      dispatch({
        type: SET_ACTIVITY_LIST,
        activities: [...current, ...activity],
      });
      return response.result;
    })
    .catch((error) => {
      console.error(error);
    });
  return res;
};

export const markAsRead = (activityId) => () => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/readUnreadActivity";
  const params = {
    activityIds: activityId,
    read: true,
  };

  return parseAction(method, url, params, { "Content-Type": "application/json" })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const setRichText = (styledText) => (dispatch) => {
  dispatch({
    type: SET_RICH_TEXT,
    richText: styledText,
  });
};

export const sendDiscussionMessage = (text, threadId, actionItem) => async (dispatch) => {
  const date = new Date();
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/sendGroupMessage:v3";
  const params = {
    originalMessageDate: date.toISOString(),
    threadId: threadId,
    text: text,
    actionItem: actionItem,
  };

  const res = await parseAction(method, url, params, { "Content-Type": "application/json" })
    .then((response) => {
      const { message } = response.result;
      return message;
    })
    .catch((error) => {
      console.error(error);
    });
  return res;
};
